.phoneTable{
  width: 100%
}
.table td{
  padding: 1%;
  vertical-align: 0%;
}
.form-check-input{
  position: relative;
}
