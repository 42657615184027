.tablePhone {  
  background-color: #f3f3f3;
  min-height: 50vh;  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);  
  border: 2px solid #6d6767;
  width: 100%;
  height: 100%;  
}
td{
  text-align: center;
  border: 1px solid #a3a0a0;   
  font-size: 11px;
}
tr{
  text-align: center;
  border: 1px solid #a3a0a0;   
  font-size: 11px;
}
th{
  text-align: center;
  border: 1px solid #a3a0a0;
  font-size: 11px;
}
thead{
  text-align: center;
  border: 1px solid #a3a0a0;    
}
tbody{
  text-align: center;
  border: 1px solid #a3a0a0;    
}
.form-control{
  font-size: 11px;
  width: 135px;
}
.header{
  background-color: #1879fe;
  color: #ffffff;
}
.btn.btn-success {
  font-size: 12px;
  width: 25%;
  height: 10%;
}
h2{
  font-size: 12px;
}
label{
  font-size: 12px;
}
.btn.btn-primary.btn-lg{
  font-size: 12px;  
}
.btn.btn-secondary.btn-lg{
  font-size: 12px;
}


